<template>
    <div>
        <div class="filter-bar">
            <a href="#"
               class="btn btn--icon-stroke"
               @click.prevent="toggleFilters"
               v-if="showFiltersButton">
        <span class="mr-4"
              v-if="!showFilters">
            <svg class="icon"
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 9"
                 width="20"
                 height="9"
                 fill="none"
                 stroke="#1D1D1B">
                <g fill-rule="evenodd"
                   stroke-linecap="round"
                   stroke-linejoin="round">
                    <path d="M16 4.5H4M19 .5H1M14 8.5H6"/>
                </g>
            </svg>
        </span>
                <span class="mr-4"
                      v-else>
            <svg class="icon icon--close"
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 14 13"
                 width="14"
                 height="13"
                 fill="none"
                 stroke="#1D1D1B">
                <g fill-rule="evenodd"
                   stroke-linecap="round"
                   stroke-linejoin="round">
                    <path d="M13.364 12.864L.636.136M13.364.136L.636 12.864"/>
                </g>
            </svg>
        </span>
                {{ translations.btnFilter }}
            </a>

            <transition name="slide-up">
                <div class="filter-bar__filters"
                     v-show="showFilters">
                    <div class="btn--filter-wrapper"
                         v-for="(filter, index) in filters"
                         :key="`filter-${ index }`"
                         v-click-outside="closeAllFilters"
                         :class="{ 'z-40': openFilter === filter.label }"
                    >
                        <a href="#"
                           class="btn--filter"
                           :class="{ open: openFilter === filter.label, 'has-selection': filter.hasSelection }"
                           @click.prevent="toggleFilter(filter.label)">{{ filter.label }}</a>
                        <ul :class="{ hidden: openFilter !== filter.label }">
                            <li v-for="(option, index) in filter.options"
                                :key="`option-${ index }`"
                                :class="{ active: option.active }">
                                <a href="#"
                                   @click.prevent="option.active = !option.active">
                                    <span>{{ option.label }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="filter-bar__input">
                        <input type="text" name="q" v-model="q" :placeholder="translations.searchTerm">
                    </div>
                    <div class="filter-bar__input">
                        <input type="text" name="location" v-model="location" :placeholder="translations.city">
                    </div>
                </div>
            </transition>
        </div>
<!--
        <transition name="fade">
            <div class="overlay"
                 v-show="showFilters"
                 @click="toggleFilters"
                 v-cloak></div>
        </transition>

        <transition name="slide-in-from-left"
                    v-cloak>
            <div class="filter-bar__mobile"
                 id="filter-bar__mobile"
                 ref="filterBarMobile"
                 v-show="showFilters"
                 v-cloak>

                <a href="#"
                   @click.prevent="toggleFilters"
                   class="btn btn--icon-stroke mb-8">
                    <svg class="icon icon--close mr-4"
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 14 13"
                         width="14"
                         height="13"
                         fill="none"
                         stroke="#1D1D1B">
                        <g fill-rule="evenodd"
                           stroke-linecap="round"
                           stroke-linejoin="round">
                            <path d="M13.364 12.864L.636.136M13.364.136L.636 12.864"/>
                        </g>
                    </svg>
                    {{ translations.btnClose }}
                </a>

                <ul v-for="(filter, index) in filters"
                    :key="`filter-${ index }`"
                    class="mb-6"
                >
                    <li>
                        <div class="mb-2">{{ filter.label }}</div>
                        <ul>
                            <li v-for="(option, index) in filter.options"
                                :key="`option-${ index }`"
                                :class="{ active: option.active }"
                                class="mb-1">
                                <a href="#"
                                   @click.prevent="option.active = !option.active">{{ option.label }}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </transition>
-->
        <div class="filter-bar__tags">
            <a v-for="(tag, index) in getActiveTags"
               href="#"
               @click.prevent="tag.active = false"
               class="filter-bar__tag"
               :key="`tag-${ index }`">
                {{ tag.label }}
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 14 13"
                     width="14"
                     height="13"
                     fill="none"
                     stroke="#1D1D1B">
                    <g fill-rule="evenodd"
                       stroke-linecap="round"
                       stroke-linejoin="round">
                        <path d="M13.364 12.864L.636.136M13.364.136L.636 12.864"/>
                    </g>
                </svg>
            </a>
        </div>

        <div class="filter-map">
            <GmapMap
                :center="mapCenter"
                :zoom="mapZoom"
                :map-type-id="mapType"
                :options="mapOptions"
                class="map"
                ref="map"
            >
                <GmapCluster :styles="clusterStyles"
                             :zoom-on-click="true"
                             :grid-size="20">
                    <GmapMarker
                        :key="index"
                        v-for="(m, index) in mapMarkers"
                        :position="m.position"
                        :icon="m.icon"
                        :clickable="true"
                        :draggable="false"
                        :title="m.title"
                        @click="markerClicked(index)"
                    >
                        <gmap-info-window :opened="infoWindow === `info-window-${ index }`"
                                          class="info-window"
                                          @closeclick="infoWindow = ''">
                            <div class="bubble">
                                <div class="bubble__title">{{ m.title }}</div>
                                <div class="bubble__address" v-html="m.address"></div>
                                <a class="bubble__link" :href="m.url" rel="nofollow noopener">
                                    {{ translations.readMore }}
                                </a>
                                <div class="bubble__badges">
                                    <span v-if="m.badgeRetex" class="badge badge--retex"></span>
                                    <span v-if="m.badgeQuest" class="badge badge--quest"></span>
                                </div>
                            </div>
                        </gmap-info-window>
                    </GmapMarker>
                </GmapCluster>
            </GmapMap>
        </div>

        <div class="filter-list">

            <template v-for="entry in entries">
                <div :key="entry.id" class="filter-list__item" :data-link="entry.url">
                    <div class="filter-list__logo">
                        <img :src="entry.logo"
                             :alt="`${ entry.title }`"
                             v-if="entry.logo !== ''">
                    </div>
                    <div class="filter-list__title">
                        {{ entry.title }}
                    </div>
                    <div class="filter-list__address">
                        {{ entry.city }} {{ entry.country }}<span v-if="entry.distance">, {{ entry.distance }}</span>
                    </div>
                    <div class="filter-list__badges">
                        <span v-if="entry.badgeRetex" class="badge badge--retex"></span>
                        <span v-if="entry.badgeQuest" class="badge badge--quest"></span>
                    </div>
                    <div class="filter-list__actions">
                        <a :href="entry.likeUrl" :class="{ active: entry.liked }"><i class="fa fa-star"></i></a>
                    </div>
                </div>
            </template>

            <div v-show="entries.length === 0">
                <span v-if="loading">{{ translations.txtLoading }}</span>
                <span v-else>{{ translations.txtEmpty }}</span>
            </div>

        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import {helperMixin} from '../js/helper-mixin.js'
import vClickOutside from 'v-click-outside'
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'
import axios from 'axios'
import {debounce} from 'debounce'
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCataCyeuCaranOt27lPLo59RxBDd0QDm4',
        libraries: 'places'
    },
    installComponents: true
});

Vue.component('GmapCluster', GmapCluster);

window.axios = axios;

export default {
    name: "CompaniesGrid",

    directives: {
        clickOutside: vClickOutside.directive
    },

    mixins: [
        helperMixin
    ],

    props: {
        categories: {
            type: Array,
            //default: [],
            required: true
        },
        translations: Object
    },

    data() {
        return {
            filters: this.categories,
            showFiltersButton: false,
            showFilters: true,
            q: '',
            location: '',
            openFilter: '',
            entries: [],
            loading: false,
            filterParams: [],
            mapMarkers: [],
            defaultMapCenter: { lat: 50.855024, lng: 4.3402849 }, // Brussels
            mapCenter: { lat: 50.855024, lng: 4.3402849 }, // Brussels
            defaultMapZoom: 8,
            mapZoom: 8,
            mapType: 'roadmap',
            mapOptions: {
                backgroundColor: '#f8f8fc',
                mapTypeControl: false,
                fullscreenControl: false,
                streetViewControl: false,
                scaleControl: false,
                zoomControl: true,
                minZoom: 2,
                restriction: {
                    latLngBounds: {
                        north: 80,
                        south: -80,
                        west: -179,
                        east: 179
                    },
                    strictBounds: true
                },
                styles: [
                    {
                        "featureType": "all",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "weight": "2.00"
                            }
                        ]
                    },
                    {
                        "featureType": "all",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#9c9c9c"
                            }
                        ]
                    },
                    {
                        "featureType": "all",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#f2f2f2"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape.man_made",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "all",
                        "stylers": [
                            {
                                "saturation": -100
                            },
                            {
                                "lightness": 45
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#eeeeee"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#7b7b7b"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#46bcec"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#c8d7d4"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#070707"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    }
                ]
            },
            clusterStyles: [
                {
                    textColor: '#ffffff',
                    textSize: 16,
                    url: '/assets/img/marker.png',
                    height: 34,
                    width: 34
                }
            ],
            infoWindow: '',
            near: '',
        }
    },

    mounted() {
        // Load products filtered by optional parameters in URL
        const queryString = window.location.search;
        this.loadEntries(queryString);
        // Activate correct filters from parameters
        //const urlParams = new URLSearchParams(queryString);
        //this.setFiltersFromParams(urlParams);
    },

    watch: {
        q: function () {
            this.searchEntries()
        },
        location: function () {
            this.searchEntries()
        },
        filters: {
            deep: true,
            handler() {
                this.filterParams = [];
                for (const obj in this.filters) {
                    const filter = this.filters[obj];
                    // Set hasSelection state to true if one of the filter options is active
                    filter.hasSelection = filter.options.some(option => option.active === true);
                    // Get all filterOptions that are active
                    let activeFilterOptions = filter.options.filter(option => option.active === true);
                    // Get only the labels of those filter options
                    let activeFilterOptionsVals = activeFilterOptions.map(obj => obj.id);
                    // Put them all into an array for later use
                    if (activeFilterOptionsVals.length > 0) {
                        this.filterParams.push({
                            'filterType': filter.handle,
                            'options': activeFilterOptionsVals
                        });
                    }
                }
                this.searchEntries();
            }
        },
        /*
        mapMarkers(markers) {
            if (markers.length > 2) {
                const bounds = new google.maps.LatLngBounds()
                for (let m of markers) {
                    bounds.extend(m.position)
                }
                this.$refs.map.fitBounds(bounds)
            }
        }
        */
    },

    methods: {

        searchEntries() {

            // Create queryParams for URL
            const params = new URLSearchParams();

            // Build up params with arrays
            // http://wolfin-dev.test/api/products.json?filterColor[]=119&shape[]=147&shape[]=154
            for (const obj in this.filterParams) {
                const item = this.filterParams[obj];
                item.options.forEach(id => {
                    params.append(`filters[${item.filterType}][]`, id);
                });
            }

            if (this.q) {
                params.append(`q`, this.q);
            }

            if (this.location) {
                params.append(`location`, this.location);
            }

            // Replace URL with params
            window.history.replaceState({}, '', `${location.pathname}?${params}`);

            // Load entries
            this.loadEntries('?' + params.toString());
        },

        loadEntries(params) {
            this.loading = true
            let data = new FormData()
            data.set('CRAFT_CSRF_TOKEN', window.csrfToken)

            // Define language of site to call action in correct language
            const lang = document.documentElement.lang && document.documentElement.lang !== 'nl' ? `/${document.documentElement.lang}` : ''

            axios({
                url: lang + '/api/companies-by-filters.json' + params,
                method: 'post',
                data: data
            }).then(result => {
                this.loading = false
                if (result.status === 200 && result['data']) {
                    this.entries = result.data.data
                    this.loadMapMarkers()
                } else {
                    console.log('ERROR loading entries', result)
                }
            });
        },

        toggleFilters() {
            this.showFilters = !this.showFilters;

            Vue.nextTick(() => {
                const el = document.getElementById('filter-bar__mobile');
                if (window.getComputedStyle(el, null).display !== 'none') {
                    this.showFilters ? disableBodyScroll(this.$refs.filterBarMobile) : enableBodyScroll(this.$refs.filterBarMobile);
                }
            });
        },

        toggleFilter(filter) {
            this.openFilter = this.openFilter === filter ? '' : filter;
        },

        closeAllFilters(e) {
            const clickedElParent = e.target.parentElement;
            if (clickedElParent && !clickedElParent.classList.contains('btn--filter-wrapper')) this.openFilter = '';
        },

        setFiltersFromParams(params) {
            /* Loop over all parameters
             * Type: String 'filters[handle][]'
             * Id: String '112'
             */
            for (const [type, id] of params) {
                // Extract handle from type 'filters[handle][]'
                let handle = type.substring(8, type.length - 3);
                // Find correct filter by handle
                let filter = this.filters.find(obj => obj.handle === handle);
                // Find correct option by Id
                if (filter) {
                    let option = filter.options.find(item => item.id === parseInt(id, 10));
                    // Activate option
                    option.active = true;
                }
            }
        },

        loadMapMarkers() {
            this.mapMarkers = this.entries.map(entry => {
                return {
                    'position': { 'lat': parseFloat(entry.lat), 'lng': parseFloat(entry.lng) },
                    'title': entry.title,
                    'address': `${ entry.street1 }<br />${ entry.zip } ${ entry.city } ${ entry.country }<br />${ entry.distance }`,
                    'url': entry.url,
                    'badgeRetex': entry.badgeRetex,
                    'badgeQuest': entry.badgeQuest,
                    'icon': '/assets/img/map-marker.png'
                };
            });
            this.centerMap();
        },

        centerMap() {
            if(this.location != '' && this.entries.length) {
                this.mapCenter = { 'lat': parseFloat(this.entries[0].lat), 'lng': parseFloat(this.entries[0].lng) };
                this.mapZoom = 12;
            }else{
                this.mapCenter = this.defaultMapCenter;
                this.mapZoom = this.defaultMapZoom;
            }
        },

        markerClicked(index) {
            this.infoWindow = `info-window-${ index }`;
        }
    },

    computed: {
        getActiveTags() {
            let activeTags = [];
            for (const obj in this.filters) {
                const filter = this.filters[obj];
                // Get all filterOptions that are active
                let activeFilterOptions = filter.options.filter(option => option.active === true);
                activeTags = [...activeTags, ...activeFilterOptions];
            }
            return activeTags;
        }
    },

    created() {
        this.searchEntries = debounce(this.searchEntries, 300)
    }
}
</script>
