<template>
    <div>
        <div class="filter-bar">
            <a href="#"
               class="btn btn--icon-stroke"
               @click.prevent="toggleFilters"
               v-if="showFiltersButton">
        <span class="mr-4"
              v-if="!showFilters">
            <svg class="icon"
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 9"
                 width="20"
                 height="9"
                 fill="none"
                 stroke="#1D1D1B">
                <g fill-rule="evenodd"
                   stroke-linecap="round"
                   stroke-linejoin="round">
                    <path d="M16 4.5H4M19 .5H1M14 8.5H6"/>
                </g>
            </svg>
        </span>
                <span class="mr-4"
                      v-else>
            <svg class="icon icon--close"
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 14 13"
                 width="14"
                 height="13"
                 fill="none"
                 stroke="#1D1D1B">
                <g fill-rule="evenodd"
                   stroke-linecap="round"
                   stroke-linejoin="round">
                    <path d="M13.364 12.864L.636.136M13.364.136L.636 12.864"/>
                </g>
            </svg>
        </span>
                {{ translations.btnFilter }}
            </a>

            <transition name="slide-up">
                <div class="filter-bar__filters filter-bar__filters--materials"
                     v-show="showFilters">
                    <div class="btn--filter-wrapper"
                         v-for="(filter, index) in filters"
                         :key="`filter-${ index }`"
                         v-click-outside="closeAllFilters"
                         :class="{ 'z-40': openFilter === filter.label }"
                    >
                        <a href="#"
                           class="btn--filter"
                           :class="{ open: openFilter === filter.label, 'has-selection': filter.hasSelection }"
                           @click.prevent="toggleFilter(filter.label)">{{ filter.label }}</a>
                        <ul :class="{ hidden: openFilter !== filter.label }">
                            <li v-for="(option, index) in filter.options"
                                :key="`option-${ index }`"
                                :class="{ active: option.active }">
                                <a href="#"
                                   @click.prevent="option.active = !option.active">
                                    <span>{{ option.label }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </transition>
        </div>
<!--
        <transition name="fade">
            <div class="overlay"
                 v-show="showFilters"
                 @click="toggleFilters"
                 v-cloak></div>
        </transition>

        <transition name="slide-in-from-left"
                    v-cloak>
            <div class="filter-bar__mobile"
                 id="filter-bar__mobile"
                 ref="filterBarMobile"
                 v-show="showFilters"
                 v-cloak>

                <a href="#"
                   @click.prevent="toggleFilters"
                   class="btn btn--icon-stroke mb-8">
                    <svg class="icon icon--close mr-4"
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 14 13"
                         width="14"
                         height="13"
                         fill="none"
                         stroke="#1D1D1B">
                        <g fill-rule="evenodd"
                           stroke-linecap="round"
                           stroke-linejoin="round">
                            <path d="M13.364 12.864L.636.136M13.364.136L.636 12.864"/>
                        </g>
                    </svg>
                    {{ translations.btnClose }}
                </a>

                <ul v-for="(filter, index) in filters"
                    :key="`filter-${ index }`"
                    class="mb-6"
                >
                    <li>
                        <div class="mb-2">{{ filter.label }}</div>
                        <ul>
                            <li v-for="(option, index) in filter.options"
                                :key="`option-${ index }`"
                                :class="{ active: option.active }"
                                class="mb-1">
                                <a href="#"
                                   @click.prevent="option.active = !option.active">{{ option.label }}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </transition>
-->
        <div class="filter-bar__tags">
            <a v-for="(tag, index) in getActiveTags"
               href="#"
               @click.prevent="tag.active = false"
               class="filter-bar__tag"
               :key="`tag-${ index }`">
                {{ tag.tag ? tag.tag : tag.label }}
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 14 13"
                     width="14"
                     height="13"
                     fill="none"
                     stroke="#1D1D1B">
                    <g fill-rule="evenodd"
                       stroke-linecap="round"
                       stroke-linejoin="round">
                        <path d="M13.364 12.864L.636.136M13.364.136L.636 12.864"/>
                    </g>
                </svg>
            </a>
        </div>

        <div class="filter-list">
            <template v-for="entry in entries">
                <div :key="entry.id" class="filter-list__item" :data-link="entry.url">
                    <div class="filter-list__logo" v-if="entry.thumb !== ''">
                        <img :src="entry.thumb"
                             :alt="`${ entry.title }`"
                             v-if="entry.thumb !== ''">
                    </div>
                    <div class="filter-list__title">
                        {{ entry.title }}
                    </div>
                    <div class="filter-list__comp">
                        {{ entry.composition }}
                    </div>
                    <div class="filter-list__actions">
                        <a :href="entry.likeUrl" :class="{ active: entry.liked }"><i class="fa fa-star"></i></a>
                    </div>
                </div>
            </template>

            <div v-show="entries.length === 0"
                 class="text-md mx-2">
                <span v-if="loading">{{ translations.txtLoading }}</span>
                <span v-else>{{ translations.txtEmpty }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import {helperMixin} from '../js/helper-mixin.js'
import vClickOutside from 'v-click-outside'
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'
import axios from 'axios'

window.axios = axios;

export default {
    name: "MaterialsGrid",

    directives: {
        clickOutside: vClickOutside.directive
    },

    mixins: [
        helperMixin
    ],

    props: {
        categories: {
            type: Array,
            //default: [],
            required: true
        },
        translations: Object
    },

    data() {
        return {
            filters: this.categories,
            showFiltersButton: false,
            showFilters: true,
            openFilter: '',
            entries: [],
            loading: false
        }
    },

    mounted() {
        // Load products filtered by optional parameters in URL
        const queryString = window.location.search;
        this.loadEntries(queryString);

        // Activate correct filters from parameters
        const urlParams = new URLSearchParams(queryString);
        this.setFiltersFromParams(urlParams);
    },

    watch: {
        filters: {
            deep: true,
            handler() {
                let filterParams = [];

                for (const obj in this.filters) {
                    const filter = this.filters[obj];

                    // Set hasSelection state to true if one of the filter options is active
                    filter.hasSelection = filter.options.some(option => option.active === true);

                    // Get all filterOptions that are active
                    let activeFilterOptions = filter.options.filter(option => option.active === true);

                    // Get only the labels of those filter options
                    let activeFilterOptionsVals = activeFilterOptions.map(obj => obj.id);

                    // Put them all into an array for later use
                    if (activeFilterOptionsVals.length > 0) {
                        filterParams.push({
                            'filterType': filter.handle,
                            'options': activeFilterOptionsVals
                        });
                    }
                }

                // Create queryParams for URL
                const params = new URLSearchParams();

                // Build up params with arrays
                // http://wolfin-dev.test/api/products.json?filterColor[]=119&shape[]=147&shape[]=154
                for (const obj in filterParams) {
                    const item = filterParams[obj];
                    item.options.forEach(id => {
                        params.append(`filters[${item.filterType}][]`, id);
                    });
                }

                // Replace URL with params
                window.history.replaceState({}, '', `${location.pathname}?${params}`);

                // Load correct products
                this.loadEntries('?' + params.toString());
            }
        }
    },

    methods: {
        loadEntries(params) {
            this.loading = true;
            let data = new FormData();
            data.set('CRAFT_CSRF_TOKEN', window.csrfToken);

            // Define language of site to call action in correct language
            const lang = document.documentElement.lang && document.documentElement.lang !== 'nl' ? `/${document.documentElement.lang}` : '';

            axios({
                url: lang + '/api/materials-by-filters.json' + params,
                method: 'post',
                data: data
            }).then(result => {
                this.loading = false;
                if (result.status === 200 && result['data']) {
                    this.entries = result.data.data;
                } else {
                    console.log('ERROR loading entries', result);
                }
            });
        },

        toggleFilters() {
            this.showFilters = !this.showFilters;

            Vue.nextTick(() => {
                const el = document.getElementById('filter-bar__mobile');
                if (window.getComputedStyle(el, null).display !== 'none') {
                    this.showFilters ? disableBodyScroll(this.$refs.filterBarMobile) : enableBodyScroll(this.$refs.filterBarMobile);
                }
            });
        },

        toggleFilter(filter) {
            this.openFilter = this.openFilter === filter ? '' : filter;
        },

        closeAllFilters(e) {
            const clickedElParent = e.target.parentElement;
            if (clickedElParent && !clickedElParent.classList.contains('btn--filter-wrapper')) this.openFilter = '';
        },

        setFiltersFromParams(params) {
            /* Loop over all parameters
             * Type: String 'filters[handle][]'
             * Id: String '112'
             */
            for (const [type, id] of params) {
                // Extract handle from type 'filters[handle][]'
                let handle = type.substring(8, type.length - 3);
                // Find correct filter by handle
                let filter = this.filters.find(obj => obj.handle === handle);
                console.log(type,id,handle,filter);
                // Find correct option by Id
                if (filter) {
                    let option = filter.options.find(item => item.id === id);
                    // Activate option
                    option.active = true;
                }
            }
        }
    },

    computed: {
        getActiveTags() {
            let activeTags = [];

            for (const obj in this.filters) {
                const filter = this.filters[obj];

                // Get all filterOptions that are active
                let activeFilterOptions = filter.options.filter(option => option.active === true);
                activeTags = [...activeTags, ...activeFilterOptions];
            }

            return activeTags;
        }
    },
}
</script>
