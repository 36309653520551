<template>
    <div class="file-upload">
        <!--UPLOAD-->
        <div v-if="isInitial || isSaving">
            <div class="file-upload__dropbox">
                <input type="file" :multiple="multiple" :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                       accept="image/png,image/jpeg,image/jpg">
                <p v-if="isInitial">
                    {{ translations.instructions }}<br>
                    {{ translations.requirements }}
                </p>
                <p v-if="isSaving">
                    {{ translations.sending }}
                </p>
            </div>
            <p>
                <a href="javascript:void(0)" @click="cancel()" v-if="assetId">{{ translations.cancel }}</a>
            </p>
        </div>
        <!--SUCCESS-->
        <div v-if="isSuccess">
            <ul>
                <li v-for="(item, index) in uploadedFiles" :key="index">
                    <img :src="item.url" :alt="item.originalName" @click="reset()">
                </li>
            </ul>
            <p>
                <a href="javascript:void(0)" @click="reset()">{{ translations.reset }}</a>
            </p>
        </div>
        <!--FAILED-->
        <div v-if="isFailed">
            <p class="error">
                {{ translations.error }}: {{ uploadError }} <br><a href="javascript:void(0)" @click="reset()">{{ translations.restart }}</a>
            </p>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

window.axios = axios;

export default {
    props: {
        sourceId: Number,
        folder: String,
        assetId: Number,
        originalName: String,
        url: String,
        translations: Object
    },
    data() {
        return {
            multiple: false,
            initialFiles: [],
            uploadedFiles: [],
            uploadError: null,
            currentStatus: null,
            uploadFieldName: 'assets-upload',
            fileCount: 0
        }
    },
    computed: {
        isInitial() {
            return this.currentStatus === STATUS_INITIAL;
        },
        isSaving() {
            return this.currentStatus === STATUS_SAVING;
        },
        isSuccess() {
            return this.currentStatus === STATUS_SUCCESS;
        },
        isFailed() {
            return this.currentStatus === STATUS_FAILED;
        }
    },
    methods: {
        reset() {
            // reset form to initial state
            this.currentStatus = STATUS_INITIAL;
            this.uploadedFiles = [];
            this.uploadError = null;

            this.$emit('upload-complete', {
                assetId: null
            });
        },
        cancel() {
            // reset form to initial state
            this.currentStatus = STATUS_SUCCESS;
            this.uploadedFiles = this.initialFiles;
            this.uploadError = null;

            this.$emit('upload-complete', {
                assetId: this.assetId
            });
        },
        save(formData) {
            // upload data to the server
            this.currentStatus = STATUS_SAVING;

            const action = '/actions/extranet/upload/upload'
            window.axios({
                method: 'post',
                headers: {
                    "Content-Type": 'application/x-www-form-urlencoded',
                },
                data: formData,
                url: action
            }).then((response) => {
                if(response.data && response.data.assetId !== undefined && response.data.assetId !== null) {
                    this.$emit('upload-complete', {
                        assetId: response.data.assetId
                    })
                    this.uploadedFiles = [].concat({
                        'originalName': response.data.filename,
                        'url': response.data.url
                    });
                    this.currentStatus = STATUS_SUCCESS;
                }else{
                    this.uploadError = 'an error occured'
                    this.currentStatus = STATUS_FAILED
                }
            }).catch((error) => {
                console.log(error)
                this.uploadError = error
                this.currentStatus = STATUS_FAILED
            })
        },
        filesChange(fieldName, fileList) {

            var allowed = ['image/png','image/jpeg','image/jpg']

            if (!fileList.length) return

            for (var file of fileList) {
                if(allowed.includes(file.type) === false) {
                    this.currentStatus = STATUS_FAILED;
                    this.uploadError = file.name + this.translations.fileNotValid
                    return
                }
                if(file.size > 5242880) {
                    this.currentStatus = STATUS_FAILED;
                    this.uploadError = file.name + this.translations.fileToBig
                    return
                }
            }

            const formData = new FormData()

            var csrfToken = document.head.querySelector('meta[name="csrf-token"]').content
            formData.set('CRAFT_CSRF_TOKEN', csrfToken)
            formData.set('sourceId', this.sourceId)
            formData.set('folder', this.folder)

            // append the files to FormData
            Array
                .from(Array(fileList.length).keys())
                .map(x => {
                    formData.append(fieldName, fileList[x], fileList[x].name);
                });

            // save it
            this.save(formData);

            //this.$emit('uploadComplete', {
            //    formData: formData,
            //    fileList: fileList
            //});
        }
    },
    mounted() {
    },
    created() {
        if(this.originalName !== undefined && this.url !== undefined && this.originalName != '' && this.url != '' && this.originalName !== null && this.url !== null) {
            this.uploadedFiles = [].concat({
                'originalName': this.originalName,
                'url': this.url
            });
            this.initialFiles = this.uploadedFiles;
            this.currentStatus = STATUS_SUCCESS;
            this.fileCount = 1;
        }else{
            this.reset();
        }
    }
}
</script>