import Vue from 'vue'
import RegisterForm from '@/components/RegisterForm'
import CompaniesGrid from '@/components/CompaniesGrid'
import MaterialsGrid from '@/components/MaterialsGrid'
import FileUpload from '@/components/FileUpload'

import { ValidationObserver, ValidationProvider, localize, extend } from 'vee-validate'
import { required, email, confirmed } from 'vee-validate/dist/rules'
//import nl from 'vee-validate/dist/locale/nl.json'
//import fr from 'vee-validate/dist/locale/fr.json'

localize({
    nl: {
        messages: {
            required: 'Dit veld is verplicht.',
            text: 'Tekst moet langer zijn dan 1 karakter.',
            email: 'Geef een geldig e-mailadres op.',
            url: 'Geef een geldige url op.',
            phone: 'Geef een geldig telefoonnummer op.',
            confirmed: 'De wachtwoorden komen niet overeen.'
        }
    },
    fr: {
        messages: {
            required: 'Ce champ est obligatoire.',
            text: 'Le texte doit comporter plus d\'un caractère.',
            email: 'Veuillez fournir une adresse email valide',
            url: 'Veuillez fournir une URL valide.',
            phone: 'Veuillez fournir un numéro de téléphone valide.',
            confirmed: 'Le mot de passe ne correspond pas'
        }
    }
})

extend('email', email)
extend('required', required)
extend('confirmed', confirmed)

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.config.productionTip = false

new Vue({
    el: '#app',
    delimiters: ['${', '}'],
    components: {
        RegisterForm,
        CompaniesGrid,
        MaterialsGrid,
        FileUpload
    },
    data: {
        // form fields
        loginName: '',
        firstName: '',
        lastName: '',
        email: '',
        newPassword: '',
        confirmPassword: '',
        currentPassword: '',
        password: '',
        phone: '',
        title: '',
        street1: '',
        zip: '',
        city: '',
        country: '',
        website: '',
        ceo: '',
        contactPerson: '',
        descriptionEntreprise: '',
        descriptionEntrepriseOtherLocale: '',
        asset: {
            folderId: 1, // Site assets
            fieldId: 45, // field Logo
            elementId: null,
            originalName: null,
            url: null
        },
        assetId: null
    },
    mounted() {

        let vm = this

        // Set language
        const language = document.head.querySelector('meta[name="language"]')
        if (language) {
            window.language = language.content
            localize(language.content)
        } else {
            console.error('language not found.')
        }

        // Select all fields with data attributes
        let fields = document.querySelectorAll('[data-initial]')
        if (fields.length) {
            fields.forEach(function (element) {
                vm[element.name] = vm.$refs[element.name].dataset.initial
            })
        }
    },
    methods: {
        onSubmit(e) {
            e.target.submit()
        },

        onUploadComplete(args) {
            this.assetId = args.assetId
        },
    }
})
