<template>
    <ValidationObserver v-slot="{ handleSubmit }">
        <form v-show="!registered" class="extranet__card" method="post" accept-charset="UTF-8" @submit.prevent="handleSubmit(() => onSubmit($event))">
            <div v-show="step == 1">
                <div>
                    <label>
                        {{ translations.company }}
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <input type="text" name="company" v-model="fields.company" :class="{ error: errors.length }">
                            <span v-if="errors.length > 0" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </label>
                </div>
                <div class="row">
                    <div class="columns large-6">
                        <label>
                            {{ translations.firstName }}
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <input type="text" name="firstName" v-model="fields.firstName" :class="{ error: errors.length }">
                                <span v-if="errors.length > 0" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </label>
                    </div>
                    <div class="columns large-6">
                        <label>
                            {{ translations.lastName }}
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <input type="text" name="lastName" v-model="fields.lastName" :class="{ error: errors.length }">
                                <span v-if="errors.length > 0" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </label>
                    </div>
                </div>
                <div>
                    <label>
                        {{ translations.email }}
                        <ValidationProvider rules="required|email" v-slot="{ errors }">
                            <input type="text" name="email" v-model="fields.email" :class="{ error: errors.length }">
                            <span v-if="errors.length > 0" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </label>
                </div>
                <div class="row">
                    <div class="columns large-6">
                        <label>
                            {{ translations.choosePassword }}
                            <ValidationProvider vid="password" rules="required" v-slot="{ errors }">
                                <input type="password" name="password" v-model="fields.password" :class="{ error: errors.length }">
                                <span v-if="errors.length > 0" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </label>
                    </div>
                    <div class="columns large-6">
                        <label>
                            {{ translations.confirmPassword }}
                            <ValidationProvider rules="required|confirmed:password" v-slot="{ errors }">
                                <input type="password" name="confirmPassword" v-model="fields.confirmPassword" :class="{ error: errors.length }">
                                <span v-if="errors.length > 0" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </label>
                    </div>
                </div>
            </div>

            <div v-if="step == 2" class="extranet__choose extranet__choose--radio">
                <h2>{{ translations.chooseCompany }}</h2>
                <ValidationProvider :rules="{required:{ allowFalse: false }}" v-slot="{ errors }">
                    <div v-for="(enterprise, index) in enterprises" :key="`enterprise-${ index }`">
                        <label :class="{ active: fields.enterprise == enterprise }">
                            <input type="radio" name="enterprise" v-model="fields.enterprise" :value="enterprise"> <b>{{ enterprise.name }}</b><span v-if="enterprise.description"><br>{{ enterprise.description }}</span>
                        </label>
                    </div>
                    <div>
                        <label :class="{ active: fields.enterprise == fields.company }">
                            <input type="radio" name="enterprise" v-model="fields.enterprise" :value="fields.company"> <b>{{ fields.company }}</b><br>{{ translations.addAsNewCompany }}
                        </label>
                    </div>
                    <span v-if="errors.length > 0" class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>

            <button type="submit" class="button" :disabled="disableSubmit">{{ translations.register }}</button>
            <a @click="resetEnterprises" v-show="step == 2">{{ translations.back }}</a>
        </form>
        <div v-show="registered" class="extranet__card">
            <p>{{ translations.thankYou }}<br>{{ translations.emailSent }}</p>
        </div>
    </ValidationObserver>
</template>

<script>
//import Vue from 'vue'
import axios from 'axios'
import { debounce } from 'debounce'

window.axios = axios

export default {
    name: "RegisterForm",

    props: {
        translations: Object
    },

    data() {
        return {
            fields: {
                company: '',
                enterprise: null,
                firstName: '',
                lastName: '',
                email: '',
                password: ''
            },
            enterprises: [],
            chooseEnterprise: false,
            step: 1,
            disableSubmit: false,
            registered: false
        }
    },

    computed: {
        // computed for use in a wachter
        company: function() {
            return this.fields.company
        },
        // computed for use in a wachter
        enterprise: function() {
            return this.fields.enterprise
        }
    },

    watch: {
        company: function() {
            if(this.company.length > 2) {
                this.searchEnterprises(this.company)
            }
        },
        enterprise: function() {
            this.chooseEnterprise = this.enterprise === null ? true : false
        }
    },

    methods: {

        resetEnterprises() {
            this.step = 1
            this.chooseEnterprise = false
            this.enterprises = []
            this.fields.enterprise = null
        },

        searchEnterprises(text) {
            this.chooseEnterprise = false
            const params = new URLSearchParams()
            params.append(`q`, text)
            axios({
                url: '/api/enterprises.json?' + params.toString(),
                method: 'get'
            }).then(result => {
                if (result.status === 200 && result['data']) {
                    this.enterprises = result.data.data
                    if (this.enterprises.length) {
                        this.chooseEnterprise = true
                    }
                } else {
                    console.log('ERROR loading enterprises', result)
                }
            })
        },

        onSubmit() {
            if(this.chooseEnterprise) {
                this.step = 2
            }else{
                this.submit()
            }
        },

        submit() {

            // Define language of site to call action in correct language
            const lang = window.language && window.language !== 'nl' ? `/${ window.language }` : ''

            let action = lang + '/actions/extranet/users/register'
            let csrfToken = document.head.querySelector('meta[name="csrf-token"]').content
            let formData = new FormData()

            formData.set('CRAFT_CSRF_TOKEN', csrfToken)
            formData.set('fields', JSON.stringify(this.fields))

            window.axios({
                method: 'post',
                headers: {
                    "Content-Type": 'application/x-www-form-urlencoded',
                },
                data: formData,
                url: action
            }).then((response) => {
                if(response.data.success == true) {
                    this.registered = true
                }else{
                    this.disableSubmit = false
                    alert(response.data.message)
                }
            }).catch((error) => {
                this.disableSubmit = false
                console.log(error)
            })
        }
    },

    created() {
        this.searchEnterprises = debounce(this.searchEnterprises, 300)
    }
}
</script>